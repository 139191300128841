<template>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-map-pin mr-1"></i> {{ $t('general.direccion') }}
            <p class="">
               <strong >{{ asegurado.direccion }}</strong>
            </p>
        </div>
        
    </div>
    <div class="row">
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.ciudad') }}
            <p class="">
               <strong>{{ asegurado.poblacion }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marker-alt mr-1"></i> {{ $t('general.cpostal') }}
            <p class="">
               <strong>{{ asegurado.codigo_postal }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marked-alt mr-1"></i> {{ $t('general.provincia') }}
            <p class="">
               <strong>{{ asegurado.provincia }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <servicio-resumen-direccion-mapa :style="estilo"  v-if="actualizar" :cliente="cliente" :ampliarmapa="ampliarmapa" :asegurado="asegurado" :datoscliente="datoscliente" :id="id" :servicio="servicio"/>
        </div>
    </div>

</template>

<script>  

import servicioresumendireccionmapa from './mapa.vue';
export default ({
        props: ['id','ampliarmapa', 'servicio', 'datoscliente','asegurado'],
     data() {
        return {
            client: [],
            actualizar: true,
            estilo: "height:250px"

        };
    },
    setup() {     
 
    },
    methods: {
        refrescarmapa() {
            this.actualizar = false;
            setTimeout(() => {

                this.actualizar = true;
            }, 500);
        }
    },
    components: {
        'servicio-resumen-direccion-mapa': servicioresumendireccionmapa,
    },
    mounted() { 

        this.$props.asegurado = this.client;
    },
    watch: {
        ampliarmapa() {

            if (this.$props.ampliarmapa == true) {
                this.estilo = "height:75vh";

            }
            else {
                this.estilo = "height:250px";
            }
            this.refrescarmapa();
        }
    }


})


</script>
