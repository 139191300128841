<template>

  <l-map v-if="centro[0] != 39.4746227" ref="map" v-model:zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom"
    :center="centro">
    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
      name="OpenStreetMap"></l-tile-layer>
    <l-marker v-if="coordenadas" :lat-lng="coordenadas"></l-marker>
  </l-map>
</template>
  <script>
  import "leaflet/dist/leaflet.css";
  import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
  import { PwgsApi } from "../../../../../services/PwgsApi";
  import L from 'leaflet';
  export default {
    props: ['id', 'servicio', 'datoscliente'],
    components: {
      LMap,
      LTileLayer,
      LMarker,
    },
    data() {
      return {
        centro:[39.4746227, -0.3477796],
        coordenadas: '',
        zoom: 13,
        iconWidth: 25,
        iconHeight: 40,
        maxZoom: 19,
        minZoom: 2,  
      };
    },
    methods: {
        async cargarcoordenadas() {
        // modulos/pwgsapi/index.php/servicios/:id/clientes
       // modulos/pwgsapi/index.php/servicios/:id/clientes
        const api = new PwgsApi();
        const datos = await api.get("servicios/" + this.$props.id + '/clientes');
        var aux1 = '';
        setTimeout(() => {
        for (var j = 0; j <= datos.asegurado.coordenadas.length; j++) {
          if (datos.asegurado.coordenadas[j] == ",") { 
              aux1 = (L.latLng(datos.asegurado.coordenadas.slice(0, j), datos.asegurado.coordenadas.slice(j + 1)));
          }
            }
          this.coordenadas = aux1;
          this.centro = aux1;
           }, 400);
      },
      refrescarmapa() {
        document.getElementById(this.$props.id).forceUpdate()
        
      },
    },
    mounted() { 
      this.cargarcoordenadas();
    },
    watch: {
        datoscliente() {
        this.$nextTick(() => {
          this.cargarcoordenadas();
        });
        },
        id() { 
        this.$nextTick(() => {
          this.cargarcoordenadas();
        });
      },
      ampliarmapa() {
        
      }
    
    }   
  };
  //https://github.com/vue-leaflet/vue-leaflet
  </script>
  <style>
  .map-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
  }

  #map {
    width: 100%;
    height: 100%;
    position: relative;
  }

</style>