<template>

  <div class="callout callout-danger position-relative overlay-wrapper" :style="colorfila">
    <div class="overlay" v-if="isGuardando == true"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2">{{$t('general.guardando')}}...</div></div>
    <div
      title="Trabajo relacionado con una visita: no se podrá eliminar este trabajo salvo que se elimine la cita o el operario tenga más líneas de trabajo a aseg/cía."
      v-if="eliminable == false" class="ribbon ribbon-top-left"><span>No eliminable</span>
    </div>
    <div class="row justify-content-between">
        <div></div> <!-- Div vacío para ocupar el espacio a la izquierda -->
        <span class="badge  nombre-cliente">{{ nombreCliente }}</span>
    </div>
    <div class="row">      
      <div class="col">
        <div class="input-group">
          <button @click="mostrarBaremo = true" type="button" class="btn btn-default btn-flat" :disabled="bloqueos.id_baremo != 0">
            <i class="fas fa-list"></i>
          </button>
          <input type="text" @input="autosave = true" class="form-control input-sm"
            v-model="trabajodatos.codigo_trabajo" :placeholder="$t('general.codigo')" :readonly="bloqueos.codigo_trabajo != 0">
        </div>
        <trabajosbaremos :visible="mostrarBaremo" :trabajodatos="trabajodatos" @update:visible="mostrarBaremo=false"/>
        <div class="form-group" v-if="mostrar.nombre != 0">
          <textarea  @input="autosave=true" class="form-control input-sm"
            v-model="trabajodatos.nombre" :placeholder="$t('general.concepto')" :readonly="bloqueos.nombre != 0"></textarea>
        </div>
        <span class="badge bg-danger">{{operarioOProveedor}}</span>
      </div>
      <div class="col">
        <div class="form-group" v-if="mostrar.descripcion_factura != 0">
          <textarea @input="autosave = true" class="form-control input-sm" v-model="trabajodatos.descripcion_factura"
            :placeholder="$t('general.descripcion')" rows="4" :readonly="bloqueos.descripcion_factura != 0"></textarea>
          <span class="input-group-text">{{ trabajodatos.nombre_especialidad}}</span>

        </div>
      </div>
      <div class="col">
        <div class="input-group " :title="$t('general.fecha') + ' '+ $t('general.subsanado')" v-if="mostrar.fecha_finaliza != 0">
          <div class="input-group-prepend">
            <span class="input-group-text">{{$t('general.f sub')}}</span>
            <Calendar @hide="guardardatos()" dateFormat="dd/mm/yy" style="width: 100%;" v-model="trabajodatos.fecha_finaliza" :disabled="bloqueos.fecha_finaliza != 0" />
          </div>
          
        </div>
      </div>
      <div class="col" style="max-width: 170px" v-if="mostrar.descuento_operario != 0">
        <select @input="autosave = true" v-model="trabajodatos.desc" class="form-control input-sm" v-if="mostrar.descr_incr != 0" :disabled="bloqueos.desc_incr != 0">
          <option value='Descuento'>Descuento</option>
          <option value='Incremento'>Incremento</option>
        </select>
        <!-- <v-select taggable v-model="trabajodatos.desc"
                    :options="[{ label: 'Descuento', code: 'Descuento' }, { label: 'Incremento', code: 'Incremento' }]" />-->
        <div class="input-group" v-if="mostrar.descuento_aplicado != 0">
          <input type="number" @input="autosave = true; calculartotal();" v-model="trabajodatos.descuento" class="form-control" :readonly="bloqueos.descuento_aplicado != 0">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="input-group" v-if="mostrar.descuento_importe != 0">
          <input @input="autosave = true; calculartotal();" type="number" v-model="trabajodatos.descuento_importe" class="form-control" :readonly="bloqueos.descuento_importe != 0">
          <div class="input-group-append">
            <span class="input-group-text">&euro;</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="input-group " v-if="mostrar.precio_operario != 0">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ $t('general.precio')}} Op.</span>
          </div>
          <input @input="autosave = true; calculartotal();"  type="Number"
             v-model="trabajodatos.precio_operario" class="form-control"
            :placeholder="$t('general.precio') + ' ' + $t('general.operario')" :readonly="bloqueos.precio_operario != 0">
        </div>
        <div class="input-group " v-if="mostrar.cantidad != 0">
          <div class="input-group-prepend">
            <span class="input-group-text">{{$t('general.cantidad')}}</span>
          </div>
          <input  @input="autosave = true; calculartotal();" type="Number"
             v-model="trabajodatos.cantidad" class="form-control" :readonly="bloqueos.cantidad != 0"
            :placeholder="$t('general.cantidad')">
        </div>
        
      </div>
      <div class="col" style="max-width: 150px">
        <div class="custom-control custom-switch custom-switch-on-success" v-if="mostrar.estado != 0">
          <input @input="autosave=false" @change="guardarsubsanado(subsanado)" v-model="subsanado" type="checkbox"
            class="custom-control-input" :id="trabajodatos.estados" :disabled="isCargandoSubsanado || bloqueos.estado != 0">
          <label class="custom-control-label" :for="trabajodatos.estados">{{$t('general.subsanado') }}</label>
           <!-- Spinner o indicador de carga -->
           <span v-if="isCargandoSubsanado" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
        <div class="custom-control custom-switch custom-switch-on-indigo++" v-if="mostrar.visto != 0">
          <input @input="autosave = false" v-model="revisado" @change="guardarrevisado(revisado)" type="checkbox"
            class="custom-control-input" :id="trabajodatos.revisado" :disabled="isCargandoRevisado || bloqueos.visto != 0">
          <label class="custom-control-label" :for="trabajodatos.revisado">{{$t('general.revisado')}}</label>
          <span v-if="isCargandoRevisado" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
        
        <div class="custom-control custom-switch custom-switch-on-warning">
          <input @input="autosave = false" v-model="reclamar" type="checkbox" class="custom-control-input"
            :id="trabajodatos.reclamarid" @change="guardarreclamado(reclamar)">
          <label class="custom-control-label" :for="trabajodatos.reclamarid">{{$t('general.reclamar')}}</label>
          <span v-if="isCargandoReclamar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
      </div>
      <div class="col" v-if="liquidado" style="display: flex; flex-direction: column; align-items: center;max-width: 120px; height: fit-content; background-color: #367A38; color: white;">
          <span >Liquidado</span>
          <span style="text-align: center;">{{ liquidadoDatos.prefijo }} nº{{ liquidadoDatos.numero }}   {{ liquidadoDatos.fecha }}</span>
      </div>
      <div v-else class="col" style="max-width: 50px">
        <button @input="autosave = false" class="btn btn-xs btn-primary"
          @click="guardardatos()">{{$t('general.guardar')}}</button>
        <button @input="autosave = false" class="btn btn-xs btn-danger"
          @click="eliminartrabajo()">{{$t('general.eliminar')}}</button>
      </div>
    </div>
    <div v-if="reclamar">
      <trabajosrealizadoreclamado :trabajodatos="trabajodatos" :guardar="guardar" />
    </div>
    <div class="precio-total-container">
        <span>{{ $t('general.preciototal') }}: </span>
        <span class="precio-total">{{ preciototal }} &euro;</span>
    </div>
  </div>
</template>
<style scoped>
/* common */
.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 175px;
  padding: 5px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

/* top left*/
.ribbon-top-left {
  top: -5px;
  left: -8px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {      

  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -20px;
  top: 25px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;  
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
.blurred {
    filter: blur(4px);
    pointer-events: none; /* Deshabilita la interacción */
    opacity: 0.6; /* Reduce la opacidad para el efecto de deshabilitado */
}

/* Posiciona el contenedor del precio total en la parte inferior derecha */
.precio-total-container {
    position: absolute;
    bottom: 10px; /* Ajusta la posición inferior según sea necesario */
    right: 10px;  /* Ajusta la posición derecha según sea necesario */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    font-size: 1rem; /* Tamaño de la fuente */
    font-weight: bold; /* Negrita para destacar */
    color: #000000; /* Color del texto */
}

/* Opcional: personaliza aún más el estilo */
.precio-total {
    color: #28a745; /* Color del texto, verde en este caso */
    font-size: 1rem; /* Tamaño de fuente para el precio */
}

.nombre-cliente {
  margin-left: auto;
  margin-right: 0; /* Opcional, asegura que el margen derecho sea cero */
  margin-bottom: 10px; /* Añade un margen inferior para separar del contenido siguiente */
}
</style>
<script>
import debounce from 'debounce';

import { PwgsApi } from '../../../../services/PwgsApi';
import trabajosrealizadoreclamado from './TrabajoRealizadoReclamado.vue';
import trabajosbaremos from './TrabajoBaremo.vue';
import Calendar from 'primevue/calendar'


export default ({
    props:['id','servicio','totales'],
    setup() {
        return;
    },
    components: {
        trabajosbaremos,
        trabajosrealizadoreclamado,
        Calendar
    },
    data() {
        return {
          mostrarBaremo:false,
          showComponent: false,
          trabajodatos: '',
          trabajoopciones: '',
          guardar: false,
          color: 'background-color: white',
          subsanado: false,
          revisado: false,
          facturar: false,
          reclamar: false,
          estado:'',
          eliminable: true,
          preciototal: '',
          colorfila: '',
          autosave: false,
          liquidado:false,
          liquidadoDatos:{},
          mostrar: [],
          bloqueos: [],
          guardadoAutomatico: null,  // Almacenará la función de debounce para guardardatos
          isGuardadoAutomaticoPendiente: false, // Variable que indica si hay un autoguardado pendiente
          isCargandoSubsanado: false, // Nuevo estado para controlar la carga del "Subsanado"
          isCargandoRevisado: false, // Nuevo estado para controlar la carga del "Revisado"
          isCargandoReclamar: false, // Nuevo estado para controlar la carga del "Reclamar"
          isGuardando: false, // Nuevo estado para controlar cuando se está guardando
        };
    },
    methods: {
        toggleComponent() {
          this.showComponent = !this.showComponent;
      },
      autoguardado() {
          if (this.autosave) {
              this.isGuardadoAutomaticoPendiente = true; // Marcar el autoguardado pendiente inmediatamente
              this.guardadoAutomatico();
              this.autosave = false; // Resetear autosave después de guardar
          }
      },
      // Cancelar autoguardado pendiente
      cancelarAutoguardado() {
          if (this.guardadoAutomatico) {
              this.guardadoAutomatico.clear(); // Cancela cualquier autoguardado pendiente
              this.autosave = false;
              this.isGuardadoAutomaticoPendiente = false; //
          }
      },
      cargardatos() {
        this.trabajodatos = this.$props.totales.datos;
        this.mostrar = this.$props.totales.opciones.mostrar;
        this.bloqueos = this.$props.totales.opciones.bloqueos;
        this.ivaseleccionado = this.trabajodatos.iva_trabajo;
        if(this.$props.totales.opciones.liquidado){
            this.liquidado = true;
            this.liquidadoDatos = this.$props.totales.opciones.liquidado.factura;
        }
        if (this.trabajodatos.estado == "No iniciado") { this.subsanado = false; }
        if (this.trabajodatos.estado == "Subsanado") { this.subsanado = true; }
        if (this.trabajodatos.visto_tramitador_trabajo == 'SI') { this.revisado = true; }
        if (this.trabajodatos.reclamacion_trabajo) { this.reclamar = true; }
        if (this.trabajodatos.facturar_trabajo == 'SI') { this.facturar = true }
        else { this.facturar = false; }
        this.trabajoopciones = this.$props.totales.opciones;
        if (this.trabajoopciones.eliminable.estado == 1) { this.eliminable = true; }
        else { this.eliminable = false; }
        if (this.trabajodatos.reclamacion_trabajo == 'SI') { this.showComponent = true; }
        this.trabajodatos.desc = "Descuento";
        this.trabajodatos.contadoid = (this.trabajodatos.idtrabajo_servicio + "contado");
        this.trabajodatos.facturarid = (this.trabajodatos.idtrabajo_servicio + "facturar");
        this.trabajodatos.estados = (this.trabajodatos.idtrabajo_servicio + "estado");
        this.trabajodatos.revisado = (this.trabajodatos.idtrabajo_servicio + "revisado");
        this.trabajodatos.reclamarid = (this.trabajodatos.idtrabajo_servicio + "reclamar");
        if (this.trabajodatos.fecha_finaliza === '0000-00-00') {
            this.trabajodatos.fecha_finaliza = null;
        } else {
            this.trabajodatos.fecha_finaliza = new Date(this.trabajodatos.fecha_finaliza).toLocaleDateString('es-ES');
        }
        if (this.trabajodatos.fecha_cita === '0000-00-00') {
            this.trabajodatos.fecha_cita = null;
        } else {
            this.trabajodatos.fecha_cita = new Date(this.trabajodatos.fecha_cita).toLocaleDateString('es-ES');
        }
        this.trabajodatos.fecha = new Date(this.trabajodatos.fecha).toLocaleDateString('es-ES');
        
        
        if (this.revisado) { this.color = "background-color:#bdea87"; }
        if (this.trabajoopciones.color_fila == "claro") {
          this.colorfila = "background-color:white";
        }
        if (this.trabajoopciones.color_fila == "FondoVerde") {
          this.colorfila = "background-color:#bdea87";
        }
        if (this.trabajoopciones.color_fila == "FondoAzul") {
          this.colorfila = "background-color: rgba(125, 173, 225, 0.56)";
        }

        this.calculartotal();
      },      
      async guardarsubsanado() {

        this.isCargandoSubsanado = true;

        if (this.isAutoguardadoPendiente()) {
            this.cancelarAutoguardado();
            await this.guardardatos(false); // Espera a que el guardado termine
        }

        let subidadatos = { estado: "" };
        if (this.subsanado) { subidadatos.estado = "Subsanado"; }
        else { subidadatos.estado = "No iniciado"; }
        const api = new PwgsApi();
        try {
          await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', subidadatos);
          this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
        }
        catch (error) {
          this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
          if (this.subsanado) this.subsanado = false;
        } finally {
            // Finalizar la carga
            this.isCargandoSubsanado = false;
        }
        this.$parent.$parent.togglerecargar();

      },
      async guardarrevisado() {

        this.isCargandoRevisado = true;

        if (this.isAutoguardadoPendiente()) {
            this.cancelarAutoguardado();
            await this.guardardatos(false); // Espera a que el guardado termine
        }

        let subidadatos = { visto: "" };
        if (this.revisado == true) { subidadatos = { visto: "SI" }; }
        else { subidadatos = { visto: "" }; }
        const api = new PwgsApi();
        try {
          await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', subidadatos);
          this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
        }
        catch (error) {
          this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
          if (this.revisado) this.revisado = false;
        }finally {
            // Finalizar la carga
            this.isCargandoRevisado = false;
        }
        this.$parent.$parent.togglerecargar();

      },
      async guardarreclamado() {

        this.isCargandoReclamar = true;

        if (this.isAutoguardadoPendiente()) {
            this.cancelarAutoguardado();
            await this.guardardatos(false); // Espera a que el guardado termine
        }

        let subidadatos = '';
        if (this.reclamar) {
          subidadatos = { reclamado_compania: 'SI', reclamado_operario: 'SI' };
        }
        else {
          subidadatos = { reclamado_compania: '', reclamado_operario: '' };
        }
        const api = new PwgsApi();
        try {
          await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', subidadatos);
          this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
        }
        catch (error) {
          this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
          if (this.reclamar) this.reclamar = false;
        } finally {
            // Finalizar la carga
            this.isCargandoReclamar = false;
        }
        this.$parent.$parent.togglerecargar();

      },
      parseFecha(fecha) {
          if (typeof fecha === 'string') {
              // Si es una cadena de texto, asumimos que está en formato DD/MM/YYYY
              let partes = fecha.split('/');
              if (partes.length === 3) {
                  // Crear un nuevo objeto Date utilizando el formato correcto
                  return new Date(partes[2], partes[1] - 1, partes[0]); // Año, Mes, Día
              } else {
                  throw new Error("Formato de fecha no válido");
              }
          } else if (fecha instanceof Date) {
              // Si ya es un objeto Date, simplemente lo devolvemos
              return fecha;
          } else {
              throw new Error("Tipo de dato no soportado para la fecha");
          }
      },  
      async guardardatos(recargar = true) {
        //PUT  modulos/pwgsapi/index.php/trabajos/:id_trabajo/modificar-trabajo
        this.isGuardando = true; // Activar el estado de guardando
        //Cancelamos guardados pendientes si le damos desde el botón también.
        if (this.isAutoguardadoPendiente()) {
            this.cancelarAutoguardado();
        }
        const api = new PwgsApi();
        let auxfechafin;
        if (this.trabajodatos.fecha_finaliza === null || this.trabajodatos.fecha_finaliza === '0000-00-00') {
            auxfechafin = '';
        } else {
            let fecha = this.parseFecha(this.trabajodatos.fecha_finaliza);
            
            // Solo formatear si la fecha es válida
            let auxdiafin = fecha.toLocaleDateString("es-ES", { day: 'numeric' });
            let auxmesfin = fecha.toLocaleDateString("es-ES", { month: 'numeric' });
            
            // Asegurar el formato de dos dígitos para el mes y día
            if (auxmesfin < 10) { auxmesfin = "0" + auxmesfin }
            if (auxdiafin < 10) { auxdiafin = "0" + auxdiafin }

            auxfechafin = auxdiafin + "/" + auxmesfin + "/" + fecha.toLocaleDateString("es-ES", { year: 'numeric' });
        }
        let subidadatos = {
          
          tipo_dano: this.trabajodatos.tipo_danyos_asitur_trabajo, nombre: this.trabajodatos.nombre, descripcion_factura: this.trabajodatos.descripcion_factura, contado: this.trabajodatos.contado,
          precio: this.trabajodatos.precio, precio_operario: this.trabajodatos.precio_operario, iva: this.ivaseleccionado,
          descuento: this.trabajodatos.descuento, descuento_operario: this.trabajodatos.descuento_operario,
          descuento_importe: this.trabajodatos.descuento_importe, cantidad: this.trabajodatos.cantidad, orden: this.trabajodatos.orden,
          destinatario_factura: this.trabajodatos.destinatario_factura, 
          fecha_finaliza: auxfechafin,
          baremos_trabajos_id_trabajo: this.trabajodatos.baremos_trabajos_id_trabajo
        }
        try {
          await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', subidadatos);
          this.guardar = true;
          this.isGuardadoAutomaticoPendiente = false; 
          if (recargar) {
              this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
              this.$parent.$parent.togglerecargar(); 
          }
        }
        catch (error) {
          this.isGuardadoAutomaticoPendiente = false; 
          this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
          if (recargar) {
              this.$parent.$parent.togglerecargar();
          }
        } finally {
            //this.isGuardando = false; // Por ahora lo desactivo que ya recarga toda la página al gaurdar
        }
      },
      async eliminartrabajo() {
        if(window.confirm('¿Quiere eliminar el trabajo a operario?')){
          //DELETE  modulos/pwgsapi/index.php/trabajos/:id_trabajo/eliminar-trabajo
          const api = new PwgsApi();
          try {
            await api.delete('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/eliminar-trabajo');
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: 'Trabajo eliminado', life: 2000 });
          }
          catch (error) {
            this.$toast.add({ severity: 'error', summary: "Error", detail: this.trabajoopciones.eliminable.mensaje, life: 5000 });
          }
          this.$parent.$parent.togglerecargar();
        }
      },
      calculartotal() {
          // Calcula el subtotal
          let subtotal = this.trabajodatos.precio_operario * this.trabajodatos.cantidad;

          // Si el descuento es en importe, calcula el porcentaje de descuento
          if (this.trabajodatos.descuento_importe != 0) {
              this.trabajodatos.descuento = (this.trabajodatos.descuento_importe / subtotal) * 100;
              this.trabajodatos.descuento = Math.round(this.trabajodatos.descuento * 100) / 100; // Redondeo a 2 decimales
          }  
          // Si el descuento es en porcentaje, calcula el precio total con descuento
          if (this.trabajodatos.descuento != 0) {
              let descuento = subtotal * (this.trabajodatos.descuento / 100);
              this.preciototal = subtotal - descuento;
          } else {
              this.preciototal = subtotal; // Sin descuento, el precio total es el subtotal
          }

          // Redondea el precio total a 2 decimales
          this.preciototal = Math.round(this.preciototal * 100) / 100;
      },
      // Método que verifica si hay un autoguardado pendiente
      isAutoguardadoPendiente() {
          return this.isGuardadoAutomaticoPendiente;
      }
  },
  watch: {
    id() {
      this.cargardatos();
    },

    autosave(val) {
        if (val === true) {
            console.log("A guardar difereido");
            this.autoguardado(); // Ejecuta el guardado cuando autosave es true
        }
    }
  },
  mounted() {
    this.cargardatos();
  },
  created() {

    // Creación de la función de debounce con un retraso de 5000 ms
    this.guardadoAutomatico = debounce(() => {
            // Crear una función async para manejar el guardado
            const guardar = async () => {
                await this.guardardatos(); // Guardar los datos
            };
            guardar(); // Ejecutar la función async
        }, 5000); // 5 segundos de espera tras el último cambio antes de guardar
  },
  computed: {
    nombreCliente() {
      if(this.servicio.clientes){
        const clienteId = this.trabajodatos.clientes_idcliente;

        // Verificar si el cliente es el asegurado
        if (this.servicio.clientes.asegurado.idcliente === clienteId) {
            return `Asegurado: ${this.servicio.clientes.asegurado.nombre}`;
        }

        // Verificar si el cliente es un perjudicado
        if (this.servicio.clientes.perjudicados && this.servicio.clientes.perjudicados.length > 0) {
          const perjudicado = this.servicio.clientes.perjudicados.find(c => c.idcliente === clienteId);
          if (perjudicado) {
              return `Perjudicado: ${perjudicado.nombre}`;
          }
        }

        // Si no es asegurado ni perjudicado
        return 'Cliente no encontrado';
      }else{
        return 'Cliente no encontrado';
      }
    },
    operarioOProveedor() {
        if (this.trabajodatos.operarios_idoperario != 0) {
            return this.$t('general.a operario');
        } else if (this.trabajodatos.proveedores_id_proveedor != 0) {
            return this.$t('general.a proveedor');
        } else {
            return '';
        }
    }
  }
})
</script>
