<template>
    <div class="row">
        <div :class="clase">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <h3 class="card-title"><i class="fas fa-calendar-alt mr-2"></i>{{ $t('general.Visitas') }}
                            </h3>
                            <div class="card-tools">
                                <button v-if="component == 'servicio-planning-calendario'" type="button" class="btn btn-tool" title="Filtro"
                                    v-on:click="mostrarfiltroavz = !mostrarfiltroavz;mostrarfiltrooperarios =false;">
                                    <i class="fas fa-filter"></i>
                                </button>
                                <button type="button" class="btn btn-tool" title="ensanchar"
                                    v-on:click="cambiarclase()">
                                    <i :class="icono"></i>
                                </button>
                                <button v-if="geograficoActivado" type="button" class="btn btn-tool" :title="titulo"
                                    v-on:click="mapacalendario()">
                                    <i :class="iconomapa"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button v-if="component == 'servicio-planning-calendario'" type="button" class="btn btn-tool" title="Leyenda" @click="toggle">
                                    <i class="fas fa-info"></i>
                                </button>
                                <OverlayPanel ref="op">
                                    <h4 style="text-align:center">{{ $t('general.leyenda') }}</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(52, 131, 171)">{{
                                                    $t('general.operarioocupado') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(235, 235, 235)">{{
                                                    $t('general.finalizadas') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(255, 232, 140)">{{
                                                    $t('general.pendientes') }}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 228px; position:unset !important;background-color: rgb(0, 204, 0)">
                                                    {{ $t('general.vacaciones') }}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 228px;background-color:#476268; color: #eee;font-size:12px;">
                                                    {{ $t('general.bloqueado') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: #cb55ac;">{{
                                                    $t('general.prioritaria') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(252, 175, 62)">{{
                                                    $t('general.conprecita') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(255, 70, 70)">
                                                    <div>{{ $t('general.porconfirmar') }}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OverlayPanel>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <!--<keep-alive>-->
                            <div v-if="component=='servicio-planning-calendario' && mostrarfiltroavz">
                                <div class="row">
                                    <div class="col-md-3">
                                    </div>
                                    <div class="col-md-6">
                                        <select v-model="especioperarioseleccionado" class="form-control input-sm">
                                            <option value="">{{ $t('general.Selecciona Especialidad') }}</option>
                                            <option :value="especi.id_especialidad" v-for="especi in especioperario"
                                                :key="especi.id_especialidad">{{especi.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row" v-if="satactivado">
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <select v-model="tiposservicioseleccionado" class="form-control input-sm">
                                            <option value="">{{ $t('general.Tipo de Servicio') }}</option>
                                            <option :value="servicio.id" v-for="servicio in tiposservicio"
                                                :key="servicio.id">{{ servicio.nombre }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <select v-model="marcaseleccionada" class="form-control input-sm">
                                            <option value="">{{ $t('general.Marca') }}</option>
                                            <option :value="marca.id_marca" v-for="marca in marcas"
                                                :key="marca.id_marca">{{ marca.nombre_marca }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <select v-model="sat" class="form-control input-sm">
                                            <option value="">SAT</option>
                                            <option value="1">SAT {{ $t('general.si') }}</option>
                                            <option value="0">SAT {{ $t('general.no') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <select v-model="guardia" class="form-control input-sm">
                                            <option value="">{{ $t('general.guardia') }}</option>
                                            <option :value="true">{{ $t('general.guardia') }} {{ $t('general.si') }}
                                            </option>
                                            <option :value="false">{{ $t('general.guardia') }} {{ $t('general.no') }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-1">
                                        <Button type="button" icon="pi pi-filter-slash" style="height: 90%;"
                                            title="Reiniciar Filtros" v-on:click="reiniciarfiltros()"></Button>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <select v-model="tiposservicioseleccionado" class="form-control input-sm">
                                            <option value="">{{ $t('general.Tipo de Servicio') }}</option>
                                            <option :value="servicio.id" v-for="servicio in tiposservicio"
                                                :key="servicio.id">{{ servicio.nombre }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <select v-model="marcaseleccionada" class="form-control input-sm">
                                            <option value="">{{ $t('general.Marca') }}</option>
                                            <option :value="marca.id_marca" v-for="marca in marcas"
                                                :key="marca.id_marca">{{ marca.nombre_marca }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <select v-model="guardia" class="form-control input-sm">
                                            <option value="">{{ $t('general.guardia') }}</option>
                                            <option :value="true">{{ $t('general.guardia') }} {{ $t('general.si') }}
                                            </option>
                                            <option :value="false">{{ $t('general.guardia') }} {{ $t('general.no') }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-1">
                                        <Button type="button" icon="pi pi-filter-slash" style="height: 90%;"
                                            title="Reiniciar Filtros" v-on:click="reiniciarfiltros()"></Button>
                                    </div>
                                </div>
                            </div>
                            <MultiSelect v-if="component == 'servicio-planning-calendario'" selectedItemsLabel="'{0} Operarios Seleccionados'" scrollHeight="400px" filter
                                v-model="operariosseleccionados" option-value="idoperario" :options="operarios"
                                optionLabel="nombre" placeholder="Selecciona Operario" :maxSelectedLabels="10"
                                class="w-full md:w-20rem" />

                            <component v-bind:is="component" :cambios="cambios" :operarios="operarios"
                                :operariosseleccionados="operariosseleccionados" :visitas="visitas" :id="id"
                                :servicio="servicio" />
                            <!--</keep-alive>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4" v-if="visitas">
             <servicio-planning-visitas :id="id" :servicio="servicio" />
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import servicioplanningvisitas from '../datos/visitas/visitas.vue';
import servicioplanningcalendario from './planning_calendario.vue';
import servicioplanningmapa from './planning_mapa.vue';
import Checkbox from 'primevue/checkbox';
import OverlayPanel from 'primevue/overlaypanel';
import MultiSelect from 'primevue/multiselect';
export default ({
    props: ['id','servicio'],
    setup() {
        return;
    },
    components: {
        'servicio-planning-visitas': servicioplanningvisitas,
        'servicio-planning-calendario': servicioplanningcalendario,
        'servicio-planning-mapa': servicioplanningmapa,
        Checkbox, OverlayPanel, MultiSelect
    },
    data (){
        return {
            geograficoActivado: false,
            component: "servicio-planning-calendario",
            visitas: '',
            operarios: [],
            mostrarfiltrooperarios: false,
            operariosseleccionados: '',
            mostrarfiltroavz: false,
            especioperario: '',
            especioperarioseleccionado:'',
            marcas: '',
            marcaseleccionada: '',
            satactivado:false,
            sat: '',
            tiposservicio: '',
            tiposservicioseleccionado: '',
            cambios: false,
            clase: "col-md-12",
            icono: "fas fa-minus",
            guardia: '',
            iconomapa: "fas fa-map-marked-alt",
            titulo:"Mapa"          
        }
    },
    methods: {
        mapacalendario() {
            if (this.iconomapa == "fas fa-map-marked-alt") {
                this.titulo = "Calendario";
                this.iconomapa = "fas fa-calendar-alt";
                this.component = "servicio-planning-mapa";
            }
            else {
                this.titulo = "Mapa";
                this.iconomapa = "fas fa-map-marked-alt";
                this.component = "servicio-planning-calendario";
            }
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        cambiarclase() {
            if (this.clase == "col-md-8") {
                this.clase = "col-md-12";
                this.icono = "fas fa-minus";
                this.$parent.mostrarf();
            }
            else {
                this.clase = "col-md-8";
                this.icono = "fas fa-plus";
                this.$parent.mostrarf2();
            }
        },
        async cargarvisitasservicio() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            
            this.visitas = '';
            const api = new PwgsApi();
            const eq = await api.get('parametros-pwgs/gestion_de_equipos');
            if (eq.gestion_de_equipos == 1) {
                this.satactivado = true;
            }
            this.visitas = await api.get('servicios/' + this.$props.id + '/visitas');
        },
        reiniciaroperarios() {
            let aux = this.operariosseleccionados;
            this.operariosseleccionados = null;
            setTimeout(() => {

                this.operariosseleccionados = aux;
            }, 1000);
        },
        reiniciarfiltros() {
            this.cambios = false;
            this.marcaseleccionada = '';
            this.tiposservicioseleccionado = '';
            this.sat = '';
            this.especioperarioseleccionado = '';
            this.guardia = '';
            //this.filtraroperarios();
        },        
        reiniciarfiltrosmenosespecialidad() {
            this.cambios = false;
            this.marcaseleccionada = '';
            this.tiposservicioseleccionado = '';
            this.sat = '';
            this.guardia = '';
            var aux = this.especioperarioseleccionado;
            console.log('auxi',aux);
            this.especioperarioseleccionado = '';
            setTimeout(() => {
                this.especioperarioseleccionado = aux;
            }, 2000);            
            //this.filtraroperarios();
        },
        async cargaroperarios() {
            let datos;
            const api = new PwgsApi();
            this.operarios = [];
            datos = await api.post("planning-pwgs/" + this.$props.id + "/operarios");
            for (let operario of Object.entries(datos)){
                this.operarios.push(operario[1]);
            }
            
            this.operarios.sort((a, b) => a.nombre.localeCompare(b.nombre));

        },
        sort(a, b){
            return a.DepartmentName.localeCompare(b.DepartmentName);
        },
        async cargarespecialidadesoperarios() {
            //GET   modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/especialidades
            const api = new PwgsApi();
            let datos;
            datos = await api.get("planning-pwgs/" + this.$props.id + "/especialidades");
            this.especioperario = datos;
        },
        async cargarmarcas() {
            //GET  modulos/pwgsapi/index.php/tipos-marca
            const api = new PwgsApi();
            let datos;
            datos = await api.get("tipos-marca");    
            this.marcas = datos.datos;  
        },
        async cargartiposservicio() {
            //GET  modulos/pwgsapi/index.php/tipos-marca
            const api = new PwgsApi();
            let datos;
            datos = await api.get("tipos-servicio");
            this.tiposservicio = datos.datos;
        },
        async filtraroperarios() {
            //POST  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/operarios
            const api = new PwgsApi();
            this.operariosaux = "";
            let subidadatos = { };
            if (this.marcaseleccionada) { subidadatos.id_marca = this.marcaseleccionada; }
            if (this.especioperarioseleccionado) { subidadatos.id_especialidad = this.especioperarioseleccionado; }
            if (this.tiposservicioseleccionado) { subidadatos.id_tipo_servicio = this.tiposservicioseleccionado; }
            if (this.sat) { subidadatos.sat = this.sat; }
            if (this.guardia) { subidadatos.guardia = this.guardia; }
            let operariosaux = '';
            operariosaux = await api.post("planning-pwgs/" + this.$props.id + "/operarios",subidadatos);
            if (this.marcaseleccionada || this.especioperarioseleccionado || this.sat) this.operariosseleccionados = [];
            if (this.operariosaux == '') { this.operariosseleccionados = []; }
            let aux = [];
            for (let operario of Object.entries(operariosaux)) {
                aux.push(operario[1].idoperario);
            }
            this.operariosseleccionados = aux;
        },
        async comprobarGeografico(){
            const api = new PwgsApi();
            const resp = await api.get('parametros-pwgs/planning_geografico');
            console.log('respui', resp);
            if(resp.planning_geografico == 1){
                this.geograficoActivado = true;            
            }
        }
    },
    mounted() {
        console.log('mntd');
        this.comprobarGeografico();
        this.cargarvisitasservicio();
        this.cargaroperarios();
        this.cargarespecialidadesoperarios();
        this.cargarmarcas();
        this.cargartiposservicio();
       
            this.component = "servicio-planning-mapa";
       
         
            this.component = "servicio-planning-calendario";
       
        this.component = "servicio-planning-calendario";
    },
    watch: {
        id() {
            this.comprobarGeografico();
            //this.operariosseleccionados = '';
            this.component = "servicio-planning-calendario";
            this.cargarvisitasservicio();
            this.cargaroperarios();
            this.cargarespecialidadesoperarios();
            this.reiniciarfiltros();
            this.iconomapa = "fas fa-map-marked-alt";
        },
        tiposservicioseleccionado() {
            if (this.tiposservicioseleccionado) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.sat = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }

            if (this.tiposservicioseleccionado == '') { this.operariosseleccionados = ''; this.cambios = false }
            
        },
        marcaseleccionada() {
            if (this.marcaseleccionada) {
                //this.cambios = true;
                //this.sat = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if (this.marcaseleccionada == '') { this.operariosseleccionados = ''; this.cambios = false }
         },
        sat() {
            if (this.sat) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if(this.sat==''){ this.operariosseleccionados = '';this.cambios = false }
        },
        guardia() {
            if (this.guardia) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.sat = '';
                this.filtraroperarios();
            }
            if (this.guardia == '') { this.operariosseleccionados = ''; this.cambios = false }
        },
        especioperarioseleccionado() {
            if (this.especioperarioseleccionado) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.sat = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if(this.especioperarioseleccionado==''){ this.operariosseleccionados = ''; this.cambios = false }
        }
    }
})
</script>
<style>
.p-multiselect-items-wrapper{
    border: 1px solid;
        color: #c1c1c1;
}
</style>