<template>
    <div class="card ">
        <div class="card-header bg-gray cabecera-siniestro">
            <h3 class="card-title"><i class="fas fa-align-left mr-2"></i>{{ $t('general.siniestro') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-siniestro" id="boton_guardar_servicio"
                    @click="guardar()">{{ $t('general.guardar') }} </button>
                <button type="button" class="btn btn-tool" title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-siniestro" title="Editar" v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-siniestro" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>
                <button type="button" class="btn btn-tool ampliar-siniestro" title="Ampliar" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool reducir-siniestro" titple="Reducir" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body body-siniestro">
            <keep-alive>
                <component :servicio="servicio" :servicioext="servicioext" :notaimportante="notaimportante"
                    :guardartoken="guardartoken" :id="id" v-bind:is="component" />
            </keep-alive>
        </div>
    </div>
</template>
<script>
import serviciodatossiniestrosimple from './siniestro_simple.vue';
import serviciodatossiniestrodetallado from './siniestro_detallado.vue';
import serviciodatossiniestroform from './siniestro_form.vue';
import $ from 'jquery'
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','servicioext'],
    setup() {
        return;
    },mounted() {
        this.ver();
        this.cargarnotas();
    },
    components: {
        'servicio-datos-siniestro-simple': serviciodatossiniestrosimple,
        'servicio-datos-siniestro-detallado': serviciodatossiniestrodetallado,
        'servicio-datos-siniestro-form': serviciodatossiniestroform,
    },
    data (){
        return {
            component: "servicio-datos-siniestro-simple",
            guardartoken: false,
            notaimportante:'',
        }
    },
    methods: {
        editar(){
            this.component = "servicio-datos-siniestro-form";
            $(".editar-siniestro").show();
            $(".ver-siniestro").hide();
            $(".cabecera-siniestro").removeClass("bg-gray");
            $(".cabecera-siniestro").addClass("bg-black");
        },
        ver(){
            this.component = "servicio-datos-siniestro-simple";
            $(".editar-siniestro").hide();
            $(".reducir-siniestro").hide();
            $(".ampliar-siniestro").show();
            $(".ver-siniestro").show();
            $(".cabecera-siniestro").removeClass("bg-black");
            $(".cabecera-siniestro").addClass("bg-gray");
        },
        ampliar(){
            this.component = "servicio-datos-siniestro-detallado";
            $(".ampliar-siniestro").hide();
            $(".reducir-siniestro").show();
            $(".cabecera-siniestro").removeClass("bg-black");
            $(".cabecera-siniestro").addClass("bg-gray");
        },
        guardar() {
            this.guardartoken = !(this.guardartoken);
        },
        async cargarnotas() {
            //get  modulos/pwgsapi/index.php/servicios/:id/notas 
            const api = new PwgsApi();
            this.notaimportante = await api.get('servicios/' + this.$props.id + '/notas');
        }
    }
})
</script>