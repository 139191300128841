<template>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-map-pin mr-1"></i> {{ $t('general.direccion') }}
            <p class="">
                <strong>{{ perjudicados.direccion }}</strong>
            </p>
        </div>
        
    </div>
    <div class="row">
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.ciudad') }}
            <p class="">
                <strong>{{perjudicados.poblacion}}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marker-alt mr-1"></i> {{ $t('general.cpostal') }}
            <p class="">
                <strong>{{perjudicados.codigo_postal }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marked-alt mr-1"></i> {{ $t('general.provincia ') }}
            <p class="">
                <strong>{{ perjudicados.provincia }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <servicio-resumen-direccion-mapa :ampliarmapa="ampliarmapa" :servicio="servicio" :id="id" :datoscliente="datoscliente"/>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header bg-gray">
                    <h4 class="card-title">
                        <i class="far fa-address-card mr-2"></i>
                       {{ $t('general.direccionesadicionales') }}
                    </h4>
                </div>
                <div class="card-body p-0">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>{{ $t('general.poblacion') }}</th>
                                <th>{{ $t('general.direccion') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="perjudicado in perjudicados" :key="perjudicado.idcliente" >
                                <td>{{perjudicado.poblacion}}</td>
                                <td>{{perjudicado.direccion}}</td>
                            </tr>
                        </tbody>
                    </table>                     
                </div>      
            </div>
        </div>
    </div>
</template>
<script>  
import servicioresumendireccionmapa from './mapa.vue';
export default ({
    props: ['id', 'servicio', 'perjudicados','ampliarmapa'],
    setup() {     
    },
    components: {
        'servicio-resumen-direccion-mapa': servicioresumendireccionmapa,
    },
})
</script>