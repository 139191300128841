<template>
    <div class="callout callout-danger">
        <div class="row">
            <div class="col">
                <div class="input-group" v-if="mostrar.id_baremo != 0">
                    <button type="button" v-if="bloqueos.id_baremo != 1" @click="toggleSelect = !toggleSelect" class="btn btn-default btn-flat">
                    <i class="fas fa-list" ></i>
                        </button>
        <v-select v-if="especialidadesb" v-model="desplegable" :options="especialidadesb" @change="toggleSelect = !toggleSelect" v-show="toggleSelect"/>
                    <input type="text"  class="form-control input-sm"  v-model="work.codigo" placeholder="Código">
                </div>
                <div class="form-group">
                    <textarea class="form-control input-sm" v-model="work.nombre" placeholder="Concepto"></textarea>
                </div>
            </div> 
            <div class="col">
                <div class="form-group">
                    <textarea class="form-control input-sm"   v-if="mostrar.descripcion_factura != 0"  v-model="work.descripcion_factura" placeholder="Descripción" rows="4"></textarea>
                </div>
            </div>
            <div class="col">
                <div class="input-group " v-if="mostrar.precio != 0">
                    <div class="input-group-prepend">
                        <span class="input-group-text input-sm">{{ $t('general.precio') }}</span>
                    </div>
                    <input type="Number"  v-model="work.precio" class="form-control input-sm" placeholder="Precio">
                </div>
                <div class="input-group " v-if="mostrar.precio_operario != 0">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.precio') }} Op.</span>
                    </div>
                    <input type="Number"  v-model="work.precio_operario" class="form-control" placeholder="Precio Operario">
                </div>
                <div class="input-group " v-if="mostrar.cantidad != 0">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.cantidad') }}</span>
                    </div>
                    <input type="Number"  v-model="work.cantidad" class="form-control" placeholder="Cantidad">
                </div>
            </div>
            <div class="col" style="max-width: 170px" v-if="mostrar.descuento != 0">
                <select  v-if="mostrar.desc_incr" v-model="work.desc_incr" class="form-control input-sm">
                    <option value="0">Descuento</option>
                    <option value="1">Incremento</option>
                </select>
                <div class="input-group">
                    <input type="number" class="form-control" v-model="work.descuento">
                    <div class="input-group-append">
                        <span class="input-group-text">%</span> 
                    </div>
                </div>
                <div class="input-group">
                    <input type="number" class="form-control" v-model="work.descuento_importe">
                    <div class="input-group-append">
                        <span class="input-group-text">&euro;</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.total') }}</span>
                    </div>
                    <input type="text" class="form-control" v-model="work.precio" placeholder="Precio" readonly>
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.total') }} Op.</span>
                    </div>
                    <input type="text" class="form-control" v-model="work.precio_operario" placeholder="Precio Operario" readonly>
                </div>
                
            </div>
            <div class="col">
                <div class="input-group" v-if="mostrar.iva_trabajo != 0">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.impuesto') }}</span>
                    </div>
                    <select v-model="work.iva_trabajo" class="form-control input-sm">
                        <option value="21.00">21,00</option>
                        <option value="10.00">10,00</option>
                        <option value="10.00">0,00</option>
                    </select>
                </div>
                <div v-if="mostrar.destinatario_factura" class="input-group" title="Destinatario">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Dest.</span>
                    </div> 
                    <select v-model="work.destinatario_presupuesto_trabajo" class="form-control input-sm">
                        <option value="Compañia">{{ $t('general.compania') }}</option>
                        <option value="Cliente">{{ $t('general.asegurado') }}</option>
                    </select>
                </div>
                <div class="input-group" title="Daños">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.danyos') }}</span>
                    </div>
                    <select  v-if="mostrar.tipo_dano" v-model="work.tipo_dano" class="form-control input-sm">
                        <option value="esteticos">Estéticos</option>
                        <option value="directos">Directos</option>
                    </select>
                </div>
            </div>
            <div class="col" style="max-width: 150px">
                <div class="custom-control custom-switch ">
                    <input v-model="aprobar_trabajo" type="checkbox"  class="custom-control-input" @change="marcarAprobado()" :id="'aprobado_' + $props.trabajo.datos.idtrabajo_presupuesto" >
                    <label class="custom-control-label" :for="'aprobado_' + $props.trabajo.datos.idtrabajo_presupuesto">{{ $t('general.aprobado') }}</label>
                </div>
                <div class="custom-control custom-switch custom-switch-on-success">
                    <input v-model="estado_trabajo" type="checkbox" class="custom-control-input" @change="marcarEstado()" :id="'estado_' + $props.trabajo.datos.idtrabajo_presupuesto">
                    <label class="custom-control-label" :for="'estado_' + $props.trabajo.datos.idtrabajo_presupuesto">{{ $t('general.subsanado') }}</label>
                </div>
                <div class="custom-control custom-switch custom-switch-on-indigo">
                    <input v-model="facturar_trabajo" type="checkbox" class="custom-control-input" @change="marcarfacturar()" :id="'facturar_' + $props.trabajo.datos.idtrabajo_presupuesto" >
                    <label class="custom-control-label" :for="'facturar_' + $props.trabajo.datos.idtrabajo_presupuesto">{{ $t('general.facturar') }}</label>
                </div>
            </div>
            <div class="col" style="max-width: 50px">
                <button @click="guardar()" class="btn btn-xs btn-success">{{ $t('general.guardar') }}</button>
                <button @click="eliminar()" class="btn btn-xs btn-danger">{{ $t('general.eliminar') }}</button>
            </div>
        </div> 
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../../services/PwgsApi';
export default ({
    props: ['trabajo','seleccionado','id'],
    setup() {
        return;
    },
    components: {
    },
    data() {
        return {
            work: this.trabajo.datos,
            mostrar: [],
            bloqueos: [],
            toggleSelect: false,
            idtrabajos: '',
            especialidadesa: [],
            especialidadesb: [],
            especialidadesbid: [],
            desplegable: [],
            miid: '',
            trabajoseleccionado: [],
            renderComponent: true,
            facturar_trabajo: false,
            estado_trabajo: false,
            aprobar_trabajo: false
        }
    },
    methods: {
        cargardatos() {

            console.log("Carga datos");
            this.work = [];
            this.work = this.$props.trabajo.datos;
            this.mostrar = this.$props.trabajo.opciones.mostrar;
            this.bloqueos = this.$props.trabajo.opciones.bloqueos;

            if(this.work.incluir_trabajo == 1){ this.facturar_trabajo = true; }
            if(this.work.estado_trabajo_presupuesto	 == "Subsanado"){ this.estado_trabajo = true; }
            if(this.work.aprobado_presupuesto_trabajo	 == "SI"){ this.aprobar_trabajo = true; }
            


        },
        async guardar() {
            //PUT  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/modificar-trabajo
            const api = new PwgsApi;
            let subidadatos;
              try {
                subidadatos = { 
                    codigo:this.work.codigo, 
                    id_baremo: this.work.id_baremo, 
                    nombre: this.work.nombre, 
                    descripcion_factura: this.work.descripcion_factura, 
                    precio: this.work.precio, 
                    precio_operario: this.work.precio_operario, 
                    descuento: this.work.descuento, 
                    descuento_importe: this.work.descuento_importe, 
                    descuento_aplicado: this.work.descuento_aplicado, 
                    cantidad: this.work.cantidad, 
                    aprobado: this.work.aprobado_presupuesto_trabajo, 
                    estado: this.work.estado_trabajo_presupuesto, 
                    destinatario_factura: this.work.destinatario_presupuesto_trabajo, 
                    tipo_dano: this.work.tipo_dano, 
                    orden: this.work.orden, 
                    facturar: this.work.incluir_trabajo, 
                    desc_incr: this.work.desc_incr, 
                    tipo_trabajo: this.work.tipo_trabajo, 
                    ancho_cristal: this.work.ancho_cristal, 
                    alto_cristal: this.work.alto_cristal, 
                    metro_lineal: this.work.metro_lineal,
                    iva:  this.work.iva_trabajo
                }
                await api.put('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/modificar-trabajo', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error', life: 5000 });
            }
        },
        async eliminar() { 
            if(window.confirm('¿Quiere eliminar el trabajo del presupuesto?')){
                //DELETE  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/eliminar-trabajo
                const api = new PwgsApi;
                try {
                    await api.delete('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/eliminar-trabajo');
                    this.$toast.add({ severity: 'success', summary: 'Eliminado', detail: 'El trabajo fue eliminado correctamente', life: 1000 });
                    this.$parent.cargartrabajos();
                    this.$parent.cargartrabajospresupuesto();
                    this.cargardatos();
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error al eliminar', detail: error, life: 5000 });
                    
                }
            }
        },
        async listartrabajos(){
                // POST  modulos/pwgsapi/index.php/servicios/id_servicio/baremo
            const api = new PwgsApi;
            let subidadatos = { id_especialidad: this.$props.trabajo.datos.especialidades_idespecialidad };
            this.especialidadesa = await api.post('servicios/' + this.$props.id + '/baremo', subidadatos);
                for (let i = 0; i < this.especialidadesa.length; i++) {
                    this.especialidadesb.push(this.especialidadesa[i].nombre);
                    this.especialidadesbid.push(this.especialidadesa[i].idtrabajo);
                }
        },
        async cambiartrabajo() {
            //GET  modulos/pwgsapi/index.php/trabajos/:id_trabajo
            for (let i = 0; i < this.especialidadesb.length; i++){
                if (this.especialidadesb[i] == this.desplegable) {
                    this.trabajoseleccionado = this.especialidadesa[i];

                    //PUT  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/modificar-trabajo
                    const api = new PwgsApi;
                    let subidadatos;
                    try {
                        subidadatos = { codigo: this.trabajoseleccionado.codigo, id_baremo: this.trabajoseleccionado.id_baremo, nombre: this.trabajoseleccionado.nombre,precio: this.trabajoseleccionado.precio, precio_operario: this.trabajoseleccionado.precio_operario, cantidad: this.trabajoseleccionado.cantidad,destinatario: this.trabajoseleccionado.destinatario}
                        await api.put('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/modificar-trabajo', subidadatos);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
                    }
                }
            }
            this.$parent.$parent.$parent.cargardatos(this.$props.id);  
        },
        marcarfacturar (){
            if (this.facturar_trabajo==true) { 
                this.work.incluir_trabajo = 1; 
            }else{
                this.work.incluir_trabajo = 0; 
            }
        },
        marcarEstado (){
            if (this.estado_trabajo==true) { 
                this.work.estado_trabajo_presupuesto = 'Subsanado'; 
            }else{
                this.work.estado_trabajo_presupuesto = ''; 
            }
        },
        marcarAprobado (){
            if (this.aprobar_trabajo==true) { 
                this.work.aprobado_presupuesto_trabajo = 'SI'; 
            }else{
                this.work.aprobado_presupuesto_trabajo = ''; 
            }
        }
    },
    mounted() {
        this.cargardatos();
        this.listartrabajos();
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },
        desplegable() { 
            this.cambiartrabajo(); 
        }
    }
})
</script>