<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">{{ $t('general.datos') }}</h3>
        </div>
        <div class="card-body">
            Nº {{ $t('general.pedido') }}
            <h4>{{pedido.id}}</h4>
            {{ $t('general.fechapedido') }}
            <h4>{{ new Date(pedido.fecha).toLocaleDateString('es-ES') }}</h4>
            {{ $t('general.Proveedor') }}
            <h4 v-if="pedido.proveedor">{{ pedido.proveedor.datos.nombre_proveedor}}</h4>
            {{ $t('general.fechaestentrega') }}
            <h4>{{ new Date(pedido.fecha_limite).toLocaleDateString('es-ES')}}</h4>
            {{ $t('general.fechaentrega') }}<br>
            <Calendar v-model="fechaentrega" id="fechafin" hourFormat="24" dateFormat="dd/mm/yy" />
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.estado') }}</label>
                <select id="exampleInputEmail1" v-model="estado" class="form-control">
                    <option value="Pendiente">{{ $t('general.Pendiente') }}</option>
                    <option value="Anulado">{{ $t('general.Anulado') }}</option>
                    <option value="Aplazado">{{ $t('general.Aplazado') }}</option>
                    <option value="Almacen">{{ $t('general.Almacen') }}</option>
                    <option value="Comunicado">{{ $t('general.Comunicado') }}</option>
                    <option value="Citado">{{ $t('general.Citado') }}</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Calendar from 'primevue/calendar';
export default {
    props: ['id', 'pedido', 'id_pedido'],
    components: {
        Calendar
    },
    data() {
        return {
            nombre: null,
            pendiente: "",
            titulo: "Datos",
            estado: "",
            fechaentrega:'',
        }
    },
        watch: {
            especialidad() {
                this.cargardatos();
            },
            pedido() {
                this.cargarestado();
            },
            estado() {
                this.cambiarestado();
            },
            fechaentrega() {
                this.enviarproveedor();
            }
        },
         methods: {
            cargardatos() {
                this.$store.dispatch('cambiarCargando', true);
                this.nombre = this.especialidad.nombre;
                this.pendiente = this.especialidad.estado_pendiente;
                 this.$store.dispatch('cambiarCargando', false);
                 this.fechaentrega = new Date(this.$props.pedido.fecha_entrega).toLocaleDateString('es-ES');
             },
             cargarestado() {
                 this.estado = this.$props.pedido.estado;
             },
             async cambiarestado() {
                 //PUT  modulos/pwgsapi/index.php/compras-pwgs/:id_pedido/estado-pedido
                 const api = new PwgsApi();
                 let subidadatos = { estado: this.estado };
                 await api.put('compras-pwgs/' + this.$props.id_pedido + '/estado-pedido', subidadatos);
             },
             async enviarproveedor() {
                 const api = new PwgsApi();
                 await api.put('compras-pwgs/' + this.$props.id_pedido + '/comunicar-pedido');
            }
    },
    mounted() {
        this.cargarestado();
 
    },
        
}
</script>