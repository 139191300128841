<template>
    <div class="card">
        <div class="card-header bg-gray">
            <div class="card-title">
                <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.historialdemensajes') }}</h3>
                <select style="margin-top: 30px;" class="form-control form-control-sm" v-model="tipoMensajeSelec" id="">
                    <option value="">Todos</option>
                    <option value="entrante">Entrantes</option>
                    <option value="saliente">Salientes</option>
                </select>
            </div>
            <div class="card-tools">
                <div v-if="ia" >
                    <img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                    style="width: 4em;height: 2em;">
                    <button type="button" class="btn btn-secondary" title="Generar resumen" @click="cargarResumen()">
                        <i class="fab fa-creative-commons-nd"></i>
                    </button>
                    <button type="button" class="btn btn-secondary" title="Consulta historial" @click="mostrartaConsulta=true">
                        <i class="far fa-question-circle"></i>
                    </button>
                </div>
            </div>
        </div>
        <Dialog v-model:visible="formulariovisible" modal header="" :style="{ width:'400px' }">
            <div style="display: flex; flex-direction: column; ">
                <div style="align-self: center;" class="form-group" v-for="campo in datosEditando.campos" :key="campo">
                    <label>{{ campo.nombre }}</label><br>
                    <Calendar style="width: 100%;" dateFormat="dd/mm/yy" v-if="campo.tipo.toLowerCase()=='text' && campo.nombre.toLowerCase() == 'fecha'" v-model="campo.valor" />
                    <InputNumber inputId="minmax" :min="0" :max="23" v-else-if="campo.tipo.toLowerCase()=='text' && campo.nombre.toLowerCase() == 'hora'" v-model="campo.valor"/>
                    <InputNumber inputId="minmax" :min="0" :max="59" v-else-if="campo.tipo.toLowerCase()=='text' && campo.nombre.toLowerCase() == 'minuto'" v-model="campo.valor"/>
                    <input v-else type="text" v-model="campo.valor" class="form-control">                    
                </div>
                <div style="text-align: end;">
                    <button @click="acionarFormulario()" class="btn btn-sm btn-primary">Aceptar</button>
                </div>
            </div>
        </Dialog>
        <div class="card-body">
            <div class="direct-chat-messages" style="height: 550px;">
                <div v-if="isloading" class="loading-container">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
                <div id="resumen" v-show="mostrarResumen">
                    <h3>Resumen:</h3>
                    <p v-html="this.resumen"></p>
                </div>
                <div id="consulta" v-show="mostrarConsulta">
                    <h3>Respuesta consulta:</h3>
                    <p v-html="this.consulta"></p>
                </div>
                <div id="taconsulta" v-show="mostrartaConsulta" style="text-align: end;">
                    <textarea v-model="textoConsulta" style="width: 100%;" id="consulta" rows="3" placeholder="Indica tu consulta"></textarea>
                    <button class="btn btn-sm btn-secondary" style="border: 1px solid grey;" @click="cargarConsulta">Enviar</button>
                </div>
                <div class="direct-chat-msg" v-for="mensaje in mensajes" :key="mensaje">
                    <div>
                        <div class="direct-chat-infos clearfix">
                            <span class="direct-chat-name float-left">{{mensaje.usuario_mensaje}}</span>
                            <div class="float-right row" style="align-items: center; gap: 15px;">
                                <div class="row" v-if="mensaje.acciones != ''" style="align-items: center;">  
                                    <label style="margin: 0;" for="">Acciones: </label>                                                                
                                    <button @click="acciones(accion, datos)" class="btn btn-sm" v-for="[accion, datos] in Object.entries(mensaje.acciones)" :title="datos.icono.title"
                                    :key="accion"><i :class="datos.icono.class" :style="datos.icono.style"></i>{{ datos.icono.text }}</button>
                                </div>
                                <span class="direct-chat-timestamp">{{mensaje.fecha_hora}}</span>
                            </div>
                        </div>                        
                        <div class="direct-chat-img">
                            <div class="row">                                
                                <div><i :class="mensaje.icono_direccion" :style="mensaje.color_direccion"></i></div>
                                <div><i :class="mensaje.tipo_comunicacion.class" :title="mensaje.tipo_comunicacion.tipo"
                                    :style="mensaje.tipo_comunicacion.style"></i><br></div>
                                <div v-if="mensaje.icono_audio"><i title="Contiene audio" :class="mensaje.icono_audio"></i></div>
                            </div>
                        </div>
                        <div class="direct-chat-text" :style="'background-color:'+mensaje.color_mensaje.split(':')[1]">
                            <span v-html="mensaje.info_mensaje"></span>
                            <p></p>
                            <b v-if="mensaje.documentos && mensaje.documentos.length>0">Documentos del mensaje:</b>
                            <p></p>
                            <a v-for="documento in mensaje.documentos" target="_blank" :key="documento"
                                :href="'https://'+urlapu+documento.url_descarga.slice(5)"><i :class="documento.icono"
                                    title="Documento"></i>{{documento.nombre}}</a>
                            <p></p>
                            <audio v-if="mensaje.fichero_audio" controls>
                                <source :src="mensaje.fichero_audio" type="audio/mpeg">
                                Tu navegador no soporta el elemento de audio
                            </audio>
                            <p></p>
                            <button class="btn btn-secondary" v-if="mensaje.transcripcion"
                                @click="mensaje.transcripcionamostrar = mensaje.transcripcion"><i
                                    v-if="mensaje.icono_transcripcion"
                                    :class="mensaje.icono_transcripcion"></i>Transcribir audio</button>
                            <p>{{mensaje.transcripcionamostrar}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','mensajes'],
    emits:[
        'recargarMensajes'
    ],
    data() {
        return {
            tipoMensajeSelec:'',
            datosEditando:[],
            accionEditando:'',
            formulariovisible: false,
            aux : "direct-chat-text",
            resumen: "",
            consulta:"",
            mostrarResumen: false,
            isloading: false,
            urlapu:'',
            ia:false,
            mostrarConsulta: false,
            mostrartaConsulta: false,
            textoConsulta:"",
        }
    },
    setup() {
        return;
    },
    components: {
        InputNumber,
        Calendar,
    },
    methods: {
        async acionarFormulario(){
            const api = new PwgsApi();
            var datos = this.datosEditando;
            var accion = this.accionEditando;
            var tipo = datos.tipo_peticion;
            var body = {accion:accion};            
            for(let campo of datos.campos){
                var nombre = campo.nombre;
                var valor = campo.valor;
                if(nombre =='fecha'){
                    valor = this.transformarFecha(valor);
                }
                body[nombre] = valor;
            }
            if(tipo.toLowerCase() == 'put'){
               await api.put([datos.accion],body)
            }else if(tipo.toLowerCase() == 'post'){
               await api.post([datos.accion],body)
            }
            this.$emit('recargarMensajes'); 
            this.formulariovisible=false;
            this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Acción realizada correctamente', life: 2000 });
        },
        transformarFecha(fechaISO){
            // Convertir la cadena a un objeto Date
            let fecha = new Date(fechaISO);

            // Obtener el día, mes y año
            let dia = String(fecha.getDate()).padStart(2, '0');
            let mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
            let anio = fecha.getFullYear();

            // Formatear la fecha en dd/mm/yyyy
            let fechaFormateada = `${dia}/${mes}/${anio}`;
            return fechaFormateada;
        },
        imprimir(mensaje){
            console.log('entra',mensaje);
        },
        async cargarConsulta(){
            if(this.textoConsulta == ""){
                alert('Indica tu consulta.')
            }else{
                try{
                    this.mostrartaConsulta=false;
                    this.isloading = true; // Activar el estado de carga
                    const api = new PwgsApi();
                    const res = await api.post("servicios/"+this.id+"/consulta-historial",{"consulta":this.textoConsulta});
                    this.consulta = res.respuesta;
                    if(!this.consulta){
                        this.consulta = "*No hay mensajes disponibles para responder la consulta*";
                    }
                }catch(e){
                    this.consulta = "Error al cargar la consulta.";
                }finally{
                    this.isloading = false; // Desactivar el estado de carga
                    this.mostrarConsulta = true;
                }
            }            
        },
        async cambio(){
            this.resumen="";
            this.mostrarResumen=false;
            this.consulta="";
            this.mostrarConsulta=false;
            this.mostrartaConsulta=false;
            this.textoConsulta="";
        },
        cargarurl() {            
            this.urlapu = localStorage.getItem('direccion');
        },
        mostrartranscripcion(idmensaje) {
            document.getElementById(idmensaje).style.visibility = "visible";
        },
        async acciones(accion, datos){
            const api = new PwgsApi();
            var tipo = datos.tipo_peticion;
            if(datos.campos){
                this.datosEditando=datos;
                this.accionEditando = accion;
                this.formulariovisible = true;
            }else{
                if(tipo.toLowerCase() == 'put'){
                   await api.put([datos.accion],{accion:accion})
                }else if(tipo.toLowerCase() == 'post'){
                   await api.post([datos.accion],{accion:accion})
                }
            }
            this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Acción realizada correctamente', life: 2000 });
            this.$emit('recargarMensajes');
        },
        async cargarResumen() {
            this.isloading = true; // Activar el estado de carga
            const api = new PwgsApi();
            try {
                let resumenax = await api.get(`servicios/${this.id}/resumen-historial`);
                this.resumen = resumenax.resumen;
                if (!this.resumen) {
                    this.resumen = "*No hay mensajes disponibles para realizar el resumen*";
                }
            } catch (error) {
                console.error("Error al cargar el resumen:", error);
                this.resumen = "Error al cargar el resumen.";
            } finally {
                this.isloading = false; // Desactivar el estado de carga
                this.mostrarResumen = true;
            }
        },
        async comprobarIa(){
            const api = new PwgsApi();
            const ia = await api.get('parametros-pwgs/inteligencia_artificial');
            if(ia['inteligencia_artificial']==1){
                this.ia=true;
            }
        }
    },
   
    mounted() { 
        this.cargarurl();
        this.comprobarIa();
    },
    watch: {
        id() {
            this.cambio();
        },
        mensajes(){
            console.log('mensajes', this.$props.mensajes);
        },
        tipoMensajeSelec(value){
            console.log('tipo', value);
            this.$emit('recargarMensajes', value);
        }
    }
})


</script>
<style>
.direct-chat-text{
    border: 0;
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.direct-chat-text::after,.direct-chat-text::before{
    border-right-color:none;
}
</style>