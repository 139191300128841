<template>
    
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <!-- Los seleccionables con buscador -->
                <label for="exampleInputEmail1">{{ $t('general.perito') }}</label>
                <v-select v-model="selected_perito" :options="listadoperitos"></v-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <strong>Informe del perito</strong>
            <textarea v-model="perito_informe" class="form-control"></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <strong>Archivos del perito</strong>
            <FileUpload mode="basic" :maxFileSize="1000000"  @select="subirarchivo($event)" @upload="uploadFile()" multiple="true" chooseLabel="Subir Archivos" /><p></p>
        </div>
    </div> 
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import FileUpload from 'primevue/fileupload';
export default ({
    props: ['id', 'servicio', 'perito','guardartest'],
    setup() {
        return;
    },
    data() {
        return {
            selected_perito: 'Sin Perito asignado',
            peritos: [],
            listadoperitos: [],
            idperito: '',
            perito_informe: '',
            archivosubido:'',
        }
    },
    components: { FileUpload, },
    methods: {
        async listaperitos() {
            //GET  modulos/pwgsapi/index.php/peritos
            this.selected_perito = this.$props.perito.nombre;
            this.perito_informe = this.$props.servicio.informe_perito;
            const api = new PwgsApi;
            let aux = await api.get("peritos");
            this.peritos = aux.datos;
            for (let i = 0; i < this.peritos.length; i++) {
                this.listadoperitos[i] = this.peritos[i].nombre;
            }
        },
        async guardarperito() {
            //PUT  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
            try {
                const api = new PwgsApi;
                let subidadatos = { id_perito: this.idperito, informe_perito:this.perito_informe };
                await api.put("servicios/" + this.$props.id + "/modificar-servicio", subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.$parent.cargarperito();
        },
        async uploadFile() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/guardar-documento
            const formData = new FormData();
            formData.append(this.archivosubido.name, this.archivosubido);
   
            const api = new PwgsApi;
            await api.post('servicios/' + this.$props.id + '/guardar-documento', formData);
        },
        subirarchivo(event) {
            this.archivosubido = event.files[0];
        },
        test() { 
              for (let i = 0; i < this.peritos.length; i++) {
                  if (this.selected_perito == this.peritos[i].nombre) { this.idperito = this.peritos[i].idperito;  }
            } 
        }
    },
    mounted() {
        this.listaperitos();
    },
    watch: {
        id() {
            this.listaperitos();
        },
        selected_perito() { 
            this.test();
        },
        guardartest() {
            this.guardarperito();
            if (this.archivosubido) {
                this.uploadFile();
            }

        }
    }
})
</script>